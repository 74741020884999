import { render, staticRenderFns } from "./package_record.vue?vue&type=template&id=6eb29519&scoped=true"
import script from "./package_record.vue?vue&type=script&lang=ts"
export * from "./package_record.vue?vue&type=script&lang=ts"
import style0 from "./package_record.vue?vue&type=style&index=0&id=6eb29519&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb29519",
  null
  
)

export default component.exports